import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

//import reportWebVitals from './reportWebVitals';
//import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { ThemeProvider } from "@mui/material/styles";

import MainApp from "./MainApp";
import { theme } from "./theme";
import { Provider } from "react-redux";

import { store } from "./app/store";
import AppUpdate from "./AppUpdate";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AppUpdate>
          <MainApp />
        </AppUpdate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

//serviceWorkerRegistration.register();

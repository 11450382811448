import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: {} };

export const instituteConfigSlice = createSlice({
  name: "instituteConfig",
  initialState,
  reducers: {
    setInstConfig: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setInstConfig } = instituteConfigSlice.actions;

export default instituteConfigSlice.reducer;

//Postman Mock server - URL
// const getBaseURL = () => {
//       return "https://7e573c0a-7c66-407d-bec2-30737dfe9d4c.mock.pstmn.io/api";
// }

//Server URL
const getBaseURL = () => {
    return "https://" + window.location.host + "/api";
}

export default getBaseURL;

import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: "Nunito, sans-serif",
  },
  palette: {
    primary: {
      main: "#2F527A",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#ca589e",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#F97316",
    },
    error: {
      main: "#EF4444",
    },
    info: {
      main: "#737ed8",
    }, //85de73ff
    success: {
      main: "#29db36",
      contrastText: "#FFFFFF",
    },
    text: {
      disabled: "rgba(0,0,0,0.65)",
      primary: "rgba(0, 0, 0, 0.95)",
    },
    collection: {
      due: "#3E8ACC",
      overdue: "#DB524B",
      collected: "#7AD300",
      outstanding: "#FF9D4B",
      txtcolor: "#ffffff",
      iconcolor: "#ffffff",
    },
    yellow: {
      main: "#d1900a",
      contrastText: "#FFFFFF",
    }
  },
});
